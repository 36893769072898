<template>
	<div class="d-flex justify-content-center align-items-center h-100">
		<div class="shadow-sm p-5">
			<div class="p-2">
				<h2>Forgot Your Password?</h2>
			</div>
			<div class="text-center my-4">
				<p class="text-danger text-center">{{ error }}</p>
				<!-- Get Email -->
				<div v-show="screen == 0">
					<p>Please enter the email you use to sign in to account</p>
					<div class="d-flex justify-content-center my-4">
						<span class="border px-2 d-flex align-items-center"
							><i class="fa fa-at"></i
						></span>
						<input
							type="email"
							placeholder="Email"
							class="p-2 w-100"
							v-model="email"
							style="border: 1px solid #ccc"
						/>
					</div>
					<button
						class="btn btn-outline-primary w-100"
						@click="
							() => {
								if (validateEmail()) {
									screen = 1;
								}
							}
						"
					>
						Next
					</button>
				</div>

				<div v-show="screen == 1">
					<p>Enter new password for account</p>
					<div class="my-4">
						<input
							type="text"
							placeholder="new password"
							class="p-2 w-100"
							v-model="new_password"
							style="border: 1px solid #ccc"
						/>
					</div>
					<button
						class="btn btn-outline-primary w-100"
						@click="
							() => {
								if (validatePassword()) {
									passwordReset();
								}
							}
						"
					>
						Request Password Reset
					</button>
				</div>

				<div v-show="screen == 2"></div>
			</div>
            <router-link to="/login">
                <p class="text-center">Back to login</p>
            </router-link>
            
		</div>
	</div>
</template>

<script>
import AdminService from "@/services/admin";

export default {
	name: "ForgotPassword",
	data() {
		return {
			// Screen 0 - Email
			// Screen 1 - Password
			// Screen 2 - Processing
			screen: 0,
			error: "",

			email: "",
			new_password: "",
		};
	},

	methods: {
		validateEmail() {
			if (this.email.length == 0) {
				this.error = "email cannot be empty";
				return false;
			}

			const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

			if (this.email.match(re) == null) {
				this.error = "email is not a proper email";
				return false;
			}

			return true;
		},

		validatePassword() {
			if (this.new_password.length == 0) {
				this.error = "password cannot be empty";
				return false;
			}

			if (this.new_password.length < 8) {
				this.error = "password needs be 8 characters long";
				return false;
			}
			return true;
		},

		passwordReset() {
			console.log("Password reset");
			AdminService.forgotPassword(this.email, this.new_password).then(
				(response) => {
					console.log(response);
				},
				(error) => {
					console.log(error);
					this.error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
};
</script>