import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_ADMIN_URL = process.env.VUE_APP_API_URL + "admin";

class AdminService {
  getAdminByEmail() {
    return axios.get(API_ADMIN_URL + "/one",{headers: authHeader()});
  }

  updateAdmin(id, data) {
    return axios.put(API_ADMIN_URL + "/" + id, data, {
      headers: authHeader(),
    });
  }

  changePassword(id, password) {
    return axios.patch(
      API_ADMIN_URL + "/" + id + "/password",
      { password: password },
      { headers: authHeader() }
    );
  }
  forgotPassword(email, password) {
    return axios.patch(API_ADMIN_URL + "/forgot/password/", {
      email: email,
      password: password,
    });
  }
}

export default new AdminService();
